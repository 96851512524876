import $ from 'jquery';
window.jQuery = window.$ = $;

$(document).ready(function () {
  $(".navbar-burger").click(function () {

    $(".navbar-burger").toggleClass("is-active");
    $(".navbar-menu").toggleClass("is-active");
  });
});
